.per-page-selector {
  width: 90px;
}
.text-elipse-400 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
[dir] .dropdown-actions > ul.dropdown-menu-right {
  transform: none !important;
}
[dir=ltr] .dropdown-actions > ul.dropdown-menu-right {
  right: 0px !important;
  left: auto !important;
}
[dir=rtl] .dropdown-actions > ul.dropdown-menu-right {
  left: 0px !important;
  right: auto !important;
}